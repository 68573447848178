import React from "react";
import AboutSection from "./AboutSection";
import ServicesSection from "./ServicesSection";
import StatsSection from "./StatsSection";
import ContactSection from "./ContactSection";
import Banner from "../../assets/images";

const HomePage = () => {
  return (
    <div className="home-page page">
      <div className="page-header">
        <img src={Banner} alt="Banner" srcset="" />
      </div>
      <AboutSection />
      <ServicesSection />
      <StatsSection />
      <ContactSection />
    </div>
  );
};

export default HomePage;
